import { useQuery } from '@apollo/react-hooks';
import { NextPage } from 'next';
import { useRouter } from 'next/router';
import React, { useEffect } from 'react';

import { IS_LOGGED_IN } from '../lib/queries';
import routes from '../lib/routes';
import Loading from './Loading';

const PublicRoute = (Page: NextPage) => {
  return () => {
    const { data, loading } = useQuery(IS_LOGGED_IN);
    const router = useRouter();
    const isLoggedIn = data?.isLoggedIn;

    useEffect(() => {
      if (process.browser && !loading && isLoggedIn) {
        router.push(routes.HOME);
      }
    }, [data]);

    if (loading || isLoggedIn) {
      return <Loading />;
    }

    return <Page />;
  };
};

export default PublicRoute;
