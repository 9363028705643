import { useMutation } from '@apollo/react-hooks';
import { strip } from '@fnando/cpf';
import { NextPage } from 'next';
import Head from 'next/head';
import React, { FormEvent, useState } from 'react';

import { LoginForm } from '../components/LoginForm';
import PublicRoute from '../components/PublicRoute';
import helpers from '../lib/helpers';
import { LOCAL_LOGIN, LOGIN } from '../lib/mutations';
import t from '../lib/translations';

const type = 'PARTNER';

const Login: NextPage = () => {
  const [login, { loading: loadingLogin, error }] = useMutation(LOGIN);
  const [localLogin, { loading: loadingLocalLogin }] = useMutation(LOCAL_LOGIN);

  const [document, setDocument] = useState('');
  const [password, setPassword] = useState('');
  const [errors, setErrors] = useState<readonly any[]>([]);

  const onSubmit = async (event: FormEvent<HTMLFormElement>): Promise<void> => {
    event.preventDefault();
    setErrors([]);
    try {
      const result = await login({
        variables: { document: strip(document), password, type },
      });
      await localLogin({ variables: result.data.login });
    } catch (error) {
      setErrors(helpers.handleError(error));
    }
  };

  const onChange = (e: any): any => {
    const value = e.target.value;
    const id = e.target.id;

    if (id === 'document') {
      setDocument(value);
    }
    if (id === 'password') {
      setPassword(value);
    }
  };

  return (
    <>
      <Head>
        <title data-testid="title">{t.LOGIN}</title>
        <link rel="icon" href="/logo.png" />
      </Head>

      <LoginForm
        document={document}
        password={password}
        errors={errors}
        onSubmit={onSubmit}
        onChange={onChange}
        loading={loadingLogin || loadingLocalLogin}
        error={error}
      />
    </>
  );
};

export default PublicRoute(Login);
