import React from 'react';
import { ErrorMessage } from 'react-hook-form';

import { ErrorInputContainerProps } from '../lib/types';
import Alert from './Alert';

const ErrorFieldContainer = ({ errors, name }: ErrorInputContainerProps): JSX.Element => (
  <ErrorMessage errors={errors} name={name}>
    {({ messages }) =>
      messages &&
      Object.entries(messages).map(([type, message]) => (
        <Alert testId="alertLogin" key={name} variant="warning">
          {message as string}
        </Alert>
      ))
    }
  </ErrorMessage>
);

export default ErrorFieldContainer;
