import Link from 'next/link';
import React from 'react';
import { useForm } from 'react-hook-form';

import i18n from '../lib/i18n';
import t from '../lib/translations';
import { LoginFormProps } from '../lib/types';
import Alert from './Alert';
import ErrorFieldContainer from './ErrorFieldContainer';
import Spinner from './Spinner';

const REQUIRED_FIELD = t.error.REQUIRED_FIELD;

export const LoginForm = ({
  errors,
  onSubmit,
  onChange,
  document,
  password,
  loading,
}: LoginFormProps): JSX.Element => {
  const { register, errors: fieldErros } = useForm({ validateCriteriaMode: 'all' });

  return (
    <div>
      <div className="login login-v1">
        <div className="login-container">
          <div className="login-header justify-center">
            <div className="inline-center">
              <img className="h-12 object-over" src="/logo.png" />
              <span className="h3-gray-cod-bold">{t.PRODUTOR_CARD_TITLE}</span>
              <span className="h3-gray-cod-thin"> {t.PARTNER}</span>
            </div>
          </div>
          <div className="login-body">
            <div className="login-content">
              {errors.map((value, index) => (
                <Alert
                  testId="alertLogin"
                  key={index}
                  variant="warning"
                  className="text-center mb-2">
                  {i18n.t(`error.${value?.error?.toLowerCase()}`)}
                </Alert>
              ))}

              <form className="margin-bottom-0" onSubmit={onSubmit}>
                <div className="form-group m-b-20">
                  <ErrorFieldContainer errors={fieldErros} name="document" />
                  <input
                    data-testid="inputDocument"
                    id="document"
                    name="document"
                    type="text"
                    className="form-control form-control-lg inverse-mode"
                    placeholder={t.DOCUMENT}
                    required
                    value={document}
                    onChange={onChange}
                    ref={register({
                      required: REQUIRED_FIELD,
                    })}
                  />
                </div>
                <div className="form-group m-b-20">
                  <ErrorFieldContainer errors={fieldErros} name="password" />
                  <input
                    data-testid="inputPassword"
                    id="password"
                    name="password"
                    type="password"
                    className="form-control form-control-lg inverse-mode"
                    placeholder={t.PASSWORD}
                    required
                    value={password}
                    onChange={onChange}
                    ref={register({
                      required: REQUIRED_FIELD,
                    })}
                  />
                </div>

                <div className="login-buttons">
                  <button
                    data-testid="btnLogin"
                    type="submit"
                    className="btn btn-success btn-block btn-lg">
                    {loading && <Spinner />}
                    {!loading && t.LOGIN}
                  </button>
                </div>
              </form>

              <Link children={<div className="login-forgot-pass">{t.SIGNUP}</div>} href="/signup" />
              <Link
                children={<div className="login-forgot-pass">{t.info.FORGOT_PASSWORD}</div>}
                href="/forgot-password"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
